const base_url='https://geektrust.s3-ap-southeast-1.amazonaws.com/';

//GET API Call
export async function getAPI(endpoint) {
    return await fetch(base_url + endpoint).then((response) => response.json()).catch((error) => handleError(error))
}

//Handling API Error
function handleError(error){
    alert('something went wrong please check the console and try again')
    console.log(error)
}


