import React, {useEffect, useState} from 'react';
import {rangeFunction} from "../utils/range";
import "./Pagination.css"

function Pagination({dataArray=[],setDataArray,...props}) {
    const [hidePagination,setHidePagination]=useState(true)
    const [lastPageNumber,setLastPageNumber]=useState(0)
    const [activePageNumber,setActivePageNumber]=useState(0)
    const [pageNumberList,setPageNumberList]=useState(0)

    useEffect(()=>{
        setActivePageNumber(0)
        if(dataArray.length<1){
            setHidePagination(true);
            setDataArray([])
        }else{
            setHidePagination(false);
            let tempLastPageNumber=Math.floor(((dataArray.length)/10))
            tempLastPageNumber=(dataArray.length%10===0)?tempLastPageNumber-1:tempLastPageNumber;
            setLastPageNumber(tempLastPageNumber)
            setPageNumberList(rangeFunction(tempLastPageNumber+1))
            let tempEndIndex=(dataArray.length)<10?dataArray.length:10
            setDataArray(dataArray.slice(0, tempEndIndex))
        }

        },[dataArray])

    const pageButtonClickFunction=(index)=>{

        setActivePageNumber(index);
        let tempIndex=index*10
        setDataArray(dataArray.slice(tempIndex, tempIndex+10))
    }

    return (
        <div>
        {!hidePagination && <div className="pagination">
        <button title={'First'} disabled={activePageNumber===0} onClick={()=>pageButtonClickFunction(0)}>{'<<'}</button>
        <button title={'Previous'} disabled={activePageNumber===0} onClick={()=>pageButtonClickFunction(activePageNumber-1)}>{'<'}</button>
            {pageNumberList && pageNumberList.map((x,i)=><button className={(i===activePageNumber)?'active':''} key={i} onClick={()=>pageButtonClickFunction(i)}>{i+1} </button>)}
        <button title={'Next'} disabled={activePageNumber===lastPageNumber} onClick={()=>pageButtonClickFunction(activePageNumber+1)}>{'>'}</button>
        <button title={'Last'} disabled={activePageNumber===lastPageNumber} onClick={()=>pageButtonClickFunction(lastPageNumber)}>{'>>'}</button>
    </div>
}
        </div>
    );
}

export default Pagination;
