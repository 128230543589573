import React, {useEffect, useState} from 'react';
import './Home.css'
import {getAPI} from "../api/Admin";
import Pagination from "../components/Pagination";
import SearchInput from "../components/SearchInput";
import deleteIcon from '../svg/delete.svg'
import editIcon from '../svg/edit.svg'
import saveIcon from '../svg/save.svg'


function Home() {
    const [memberList,setMemberList]=useState([]);
    const [searchList,setSearchList]=useState([]);
    const [tableDataArray,setTableDataArray]=useState([]);
    const [checkedItemList,setCheckedItemList]=useState([])
    const [editItemList,setEditItemList]=useState([])

    useEffect(()=>{
        loadMemberList()
    },[])

    //load member list from api
    const loadMemberList = ()=>{
        getAPI('adminui-problem/members.json').then((response) => {
            setMemberList(response);
        })
    }

    //select all items from the page
    const selectAll = (event)=>{

        if(event.target.checked){
            setCheckedItemList([])
            let tempList=tableDataArray.map((a)=>a.id);
            setCheckedItemList(tempList)
        }else{
            setCheckedItemList([])
        }

    }

    // select single item
    const itemCheck=(event,id)=>{
        if(event.target.checked){
            if(!checkedItemList.includes(id)){
                checkedItemList.push(id)
            }
        }else{
           let tempIndex=checkedItemList.indexOf(id)
            if(tempIndex!==-1){
                checkedItemList.splice(tempIndex,1)
            }
        }
        setCheckedItemList([...checkedItemList])
    }

    //delete selected items
    const deleteSelected = ()=>{
      checkedItemList.forEach((a)=>deleteAction(a))
      setCheckedItemList([])
    }
    //delete action
    const deleteAction = (id)=>{
        if(id){
            let deleteIndex=memberList.findIndex((a,i)=>{return a.id===id})
            if(deleteIndex!==-1) {
                memberList.splice(deleteIndex, 1)
                setMemberList([...memberList])
            }
        }
    }

    const editClick=(member,status=true)=>{
        let id=member.id;
        if(id) {

            if(status) {
                  editItemList.push(id)
                setEditItemList([...editItemList]);
            }else{
                let tempIndex=editItemList.indexOf(id);
                if(tempIndex!==-1){
                    if(member.name && member.email && member.role) {
                        editItemList.splice(tempIndex, 1)
                        setEditItemList([...editItemList]);
                    }else{
                        alert('Please fill all the fields and save')
                    }
                }
            }

        }
    }

    const valueChange=(value,id,field)=>{
        let editIndex=memberList.findIndex((a,i)=>{return a.id===id})
        if(editIndex!==-1) {
            memberList[editIndex][field]=value;
            setMemberList([...memberList])
        }
    }


    return (
        <div className={'container'}>
            <h2>Admin UI</h2>
<SearchInput dataArray={memberList} searchFieldList={['name','email','role']} setDataArray={setSearchList}/>
<table>
    <thead>
    <tr>
        <th>
            <input type={"checkbox"} checked={checkedItemList.length===tableDataArray.length && tableDataArray.length!==0} onChange={selectAll}/>
        </th>
        <th>Name</th>
        <th>Email</th>
        <th>Role</th>
        <th>Actions</th>

    </tr>

    </thead>
    <tbody>
    {tableDataArray && tableDataArray.map((member,index)=>{return (<tr key={index} style={{background:checkedItemList.includes(member.id)?'#a9a9a9a1':'#fff'}} >
    <td>
        <input type={"checkbox"} checked={checkedItemList.includes(member.id)} onChange={(event)=>{itemCheck(event,member.id)}} />
    </td>
    <td>
        {editItemList.includes(member.id)?<input type={'text'} onChange={(event)=>{valueChange(event.target.value,member.id,'name')}} value={member.name}/>:member.name}
    </td>
    <td>
        {editItemList.includes(member.id)?<input type={'email'} onChange={(event)=>{valueChange(event.target.value,member.id,'email')}} value={member.email}/>:member.email}
    </td>
    <td style={{textTransform:'capitalize'}}>
        {editItemList.includes(member.id)?<input type={'text'} onChange={(event)=>{valueChange(event.target.value,member.id,'role')}} value={member.role}/>:member.role}
    </td>
        <td>
            {editItemList.includes(member.id) && <a style={{marginRight: '5px'}} onClick={() => {
                editClick(member, false)
            }}>
                <img style={{color: 'green'}} className={'icon'} alt={'save'} src={saveIcon}/>
            </a>}
            {!editItemList.includes(member.id) &&<a style={{marginRight: '5px'}} onClick={() => {
                editClick(member)
            }}>
                <img style={{color: 'green'}} className={'icon'} alt={'edit'} src={editIcon}/>
            </a>}
            <a onClick={()=>deleteAction(member.id)}>
                <img  style={{color:'red'}} className={'icon'} alt={'delete'} src={deleteIcon}/>
            </a>


        </td>
    </tr>)})}

    </tbody>
</table>
            {tableDataArray.length<1 && <div className="card">Sorry! No Records Found</div>}
            <div style={{display:"flex",justifyContent:"space-between"}}>
               <div> {checkedItemList.length>0 && <button className={'delete-btn'} onClick={deleteSelected}>Delete Selected</button>}</div>
              <div>  {searchList && <Pagination dataArray={searchList} setDataArray={(data)=>{setTableDataArray(data);setCheckedItemList([])}}/>}</div>

            </div>

        </div>
    );
}

export default Home;
