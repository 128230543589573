import React, {useEffect} from 'react';
import "./SearchInput.css"

function SearchInput({dataArray=[],searchFieldList=[],setDataArray}){

    useEffect(()=>{
        setDataArray(dataArray)
    },[dataArray])
    const searchFunction=(event)=>{
        let searchValue=event.target.value
        if(searchValue.length<1 || searchFieldList.length<1){
            setDataArray(dataArray)
        }else {
            let tempSearchList = []
            for (let i = 0; i < dataArray.length; i++) {
                for (let j = 0; j < searchFieldList.length; j++) {
                    let tempData = dataArray[i][searchFieldList[j]];
                    if (tempData && tempData.toLowerCase().startsWith(searchValue.toLowerCase())) {
                        tempSearchList.push(dataArray[i]);
                        break;
                    }
                }
            }
            setDataArray(tempSearchList)
        }
    }

    return (
        <div>
            <input type={'text'} className="search-input" onInput={searchFunction} placeholder={'Search Here...'}/>
        </div>
    );
}

export default SearchInput;
